import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { base_url, postHeader } from "../../../../Helpers/request";
import { useState } from "react";
import { toast } from "react-toastify";

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true);
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setLoading(false);
          setBanner(response.data.data);
          localStorage.setItem(
            "bw-bannerdata",
            JSON.stringify(response.data.data)
          );
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <div
        className="venice_hero_slider"
        // style={{
        //   backgroundImage: `url(${
        //     isMobileOnly ? val?.mobile_image : val?.image
        //   })`,
        // }}
      >
        {banner?.map((val, index) => (
        <div className="venice_hero_img">
          {!loading ? <img className="m-auto img-fluid" alt="" src={isMobileOnly ? val?.mobile_image : val?.image} /> :  <Skeleton animation="wave" height={700} />}
          {val.btn_text !== "" ? "" : (
                <div
                  className={`${
                    isMobile ? "text-center banner-content" : val.text_direction_class + ' banner-content'
                  }`}
                >
                  <div>
                  <h1 data-aos="fade-up-left" data-aos-duration="700">
                    {val.title}
                  </h1>
                  <p
                    data-aos="fade-up-left"
                    data-aos-duration="1200"
                    data-aos-delay="300"
                  >
                    {val.short_text}
                  </p>
            
                  </div>
                </div>
              )}  
        </div>
        ))}
      </div>
    </>
  );
};

export default BannerSlider;
